export function ensure(name, object, requiredKeys) {
  const errors = [];

  requiredKeys.forEach((key) => {
    if (isUndefined(object[key])) {
      errors.push(`Required key ${key} missing from options`);
    }
  });

  if (errors.length > 0) {
    throw new Error(`Error calling ${name}:\n${errors.join('\n')}`);
  }
}

export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

export function isUndefined(value) {
  return value === undefined;
}

export function getBootstrapData(key, optional = false) {
  const value = window.TMPBootstrapData[key];

  if (!optional && isUndefined(value)) {
    throw new Error(`Required bootstrap value ${key} is absent \
                     did you forget to call set_bootstrap_data in the controller?`);
  }

  return value;
}

export function abstractMethod(name) {
  throw new Error(
    `${name} is an abstract method and must be implemented in the child class`
  );
}

export function getDomain(url) {
  const a = document.createElement('a');
  a.href = url;
  return a.hostname.replace('//www.', '//');
}

export function isMobileViewport() {
  return window.innerWidth <= 768;
}

// Lifted from https://davidwalsh.name/query-string-javascript
export function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

// Lifted from https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function renderClassToElement(klass, selector, options = {}) {
  const $els = $(selector);

  if ($els.length > 0) {
    $els.each((index, el) => {
      const instance = new klass({ el });
      if (!options.skipRender) {
        instance.render();
      }
    });
  }
}

export function whenElementExists(selectors, fn) {
  if (document.querySelector(selectors)) {
    fn();
  }
}

export function dynamicRenderClassToElement(
  selectors,
  loadFn,
  { skipRender } = {}
) {
  const els = document.querySelectorAll(selectors);
  const count = els.length;

  // we have one, keep going
  if (count > 0) {
    loadFn().then(({ default: Class }) => {
      for (let idx = 0; idx < count; idx++) {
        const el = els[idx];
        const instance = new Class({ el });

        if (!skipRender) {
          instance.render();
        }
      }
    });
  }
}

export function loadScript(url) {
  const tag = document.createElement('script');
  tag.src = url;
  tag.async = true;
  document.body.append(tag);
}
