// packages
import debounce from 'underscore/modules/debounce.js';
import delay from 'underscore/modules/delay.js';

const sizer = document.querySelector('.vertical-hed-caption-sizer-js');

if (sizer) {
  if (sizer.complete && sizer.naturalWidth > 1) {
    resizeCaption(sizer);
    // This silliness fixes firefox somehow someway
    delay(resizeCaption.bind(undefined, sizer), 50);
  } else {
    sizer.addEventListener('load', function () {
      resizeCaption(sizer);
      delay(resizeCaption.bind(undefined, sizer), 50);
    });
  }

  window.addEventListener(
    'resize',
    debounce(resizeCaption.bind(this, sizer), 100)
  );
}

// adapted from: https://www.npmjs.com/package/intrinsic-scale
function getObjectFitSize(
  contains /* true = contain, false = cover */,
  containerWidth,
  containerHeight,
  width,
  height
) {
  const imgAspectRatio = width / height;
  const containerAspectRatio = containerWidth / containerHeight;
  let targetWidth = 0;
  let targetHeight = 0;
  const test = contains
    ? imgAspectRatio > containerAspectRatio
    : imgAspectRatio < containerAspectRatio;

  if (test) {
    targetWidth = containerWidth;
    targetHeight = targetWidth / imgAspectRatio;
  } else {
    targetHeight = containerHeight;
    targetWidth = targetHeight * imgAspectRatio;
  }

  return {
    width: targetWidth,
    height: targetHeight,
    x: (containerWidth - targetWidth) / 2,
    y: (containerHeight - targetHeight) / 2,
  };
}

function resizeCaption(/** @type {HTMLImageElement} */ img) {
  const { width, x } = getObjectFitSize(
    true /* contain */,
    img.width,
    img.height,
    img.naturalWidth,
    img.naturalHeight
  );

  const caption = document.querySelector('.vertical-hed-caption-js');

  if (caption) {
    caption.style.width = `${width}px`;
    caption.style.paddingLeft = `${x}px`;
  }
}
