function setup_document_cloud() {
  // If the note embed is already loaded, don't repeat the process.
  if (window.dc && window.dc.noteEmbedLoaded) return;

  window.dc = window.dc || {};
  window.dc.recordHit = 'https://www.documentcloud.org/pixel.gif';

  function loadCSS(url, media) {
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.media = media || 'screen';
    link.href = url;
    var head = document.getElementsByTagName('head')[0];
    head.appendChild(link);
  }

  /*@cc_on
    /*@if (@_jscript_version < 5.8)
      loadCSS('https://s3.amazonaws.com/s3.documentcloud.org/note_embed/note_embed.css');
    @else @*/
  loadCSS(
    'https://s3.amazonaws.com/s3.documentcloud.org/note_embed/note_embed-datauri.css'
  );
  /*@end
    @*/

  // Record the fact that the note embed is loaded.
  dc.noteEmbedLoaded = true;

  // Request the embed JavaScript.
  // (done manually in the footer)
}

function setup_doc_cloud_size_hacks() {
  const noteElements = document.querySelectorAll('.dc-note-excerpt');

  noteElements.forEach((element) => {
    const noteWrap = element.closest('.dc-note-excerpt-wrap');
    const width = noteWrap.getBoundingClientRect().width - 1;

    if (!document.body.classList.contains('desktop')) {
      element.style.width = `${width}px`;
      element.querySelector('.DC-right-cover').remove();
    }
  });
}

window.addEventListener(
  'load',
  () => {
    setTimeout(() => {
      setup_doc_cloud_size_hacks();
    }, 2000);

    setup_doc_cloud_size_hacks();
  },
  { once: true }
);
