// local
import { trackEvent } from './helpers.js';

const top_of_post = document.querySelector('span[data-mark="top-of-post"]');
const bottom_of_post = document.querySelector(
  'span[data-mark="bottom-of-post"]'
);
/** @type {Set<number>} */
const events_sent = new Set();
const fractions = [0.0, 0.2, 0.4, 0.6, 0.8, 1.0];

if (top_of_post && bottom_of_post) {
  const post_height = bottom_of_post.offsetTop - top_of_post.offsetTop;

  const scroll_markers = fractions.map((fraction) => {
    return { fraction, pixels: fraction * post_height + top_of_post.offsetTop };
  });

  function check_scroll_depth() {
    const window_bottom = window.scrollY + window.innerHeight;

    scroll_markers
      .filter((marker) => {
        return marker.pixels <= window_bottom;
      })
      .forEach((active_marker) => {
        if (events_sent.has(active_marker.fraction)) {
          // already sent this event, no-op
        } else {
          events_sent.add(active_marker.fraction);
          const percent_scroll_event_label = `${100 * active_marker.fraction}%`;
          trackEvent('story_scrollmark', {
            percent: percent_scroll_event_label,
            fraction: active_marker.fraction,
          });
        }
      });
  }

  check_scroll_depth();

  window.addEventListener('tmp_scroll', () => check_scroll_depth());
}
