import { ScrollController, ScrollScene } from '@themarshallproject/scrolling';

export { ScrollScene };
export const globalScrollService = new ScrollController();

// FIXME: get rid of the globals
// This is just to support graphics rig projects that used the global variable,
// until we can identify and update all of them.
window.TMPScrolling = {
  ScrollScene,
  scrollService: globalScrollService,
};
