export function AnimationContext() {
  this.stylesheet = createStylesheet();
}

function createStylesheet() {
  var stylesheet = document.createElement('style');
  document.head.appendChild(stylesheet);
  return stylesheet.sheet;
}

AnimationContext.prototype.clear = function () {
  for (var i = this.stylesheet.cssRules.length; i > 0; i--) {
    this.stylesheet.deleteRule(0);
  }
};

AnimationContext.prototype.createKeyframeRules = function (name, frames) {
  var prefixes = ['-o-', '-moz-', '-webkit-', ''];
  return prefixes.map(function (prefix) {
    return '@' + prefix + 'keyframes ' + name + ' {\n' + frames + '\n}';
  });
};

AnimationContext.prototype.insertKeyframe = function (name, frames) {
  var self = this;
  this.createKeyframeRules(name, frames).forEach(function (rule) {
    // This will raise an exception if the wrong prefix is used.
    try {
      self.stylesheet.insertRule(rule, self.stylesheet.cssRules.length);
    } catch (e) {}
  });
};

AnimationContext.prototype.insertRule = function (rule, beginning) {
  var index = beginning ? 0 : this.stylesheet.cssRules.length;
  this.stylesheet.insertRule(rule, index);
};
