// Adapted from https://stackoverflow.com/questions/5639346/what-is-the-shortest-function-for-reading-a-cookie-by-name-in-javascript
export function getCookie(name) {
  const result = new RegExp(`(?:^|; )${name}=([^;]*)`).exec(document.cookie);
  return result ? result[1] : null;
}

window.readCookie = window.read_cookie = getCookie;

export function setCookie(key, value, maxAgeInDays = 1500) {
  const maxAge = maxAgeInDays * 24 * 60 * 60;
  document.cookie = `${key}=${value};max-age=${maxAge};path=/`;
}

window.setCookie = window.set_cookie = setCookie;
