// packages
import _delay from 'underscore/modules/delay.js';

// local
import { trackEvent } from '../analytics/helpers.js';
import { AnimationContext } from '../animations/animations.js';
import EmailSignupService from '../subscribe/emailsignupservice.js';
import http from '$shared/http.js';
import { renderClassToElement } from '$shared/utils.js';
import { appendStringIntoElement } from '../util/dom.js';
import { createEvent } from '../util/events.js';

/**
 * Simple logic for a small 'toast' notification that pops up in the bottom-right of the page.
 */
const toastContainer = document.querySelector('.toast-notifications-container');

if (toastContainer) {
  loadToast();
}

const COUNT_KEY = 'toast-pageview-count';
const RESPONDED_KEY = 'toast-has-responded';

function shouldShowToast() {
  if (window.sessionStorage.getItem(RESPONDED_KEY)) {
    return false;
  }

  const currentValue = window.sessionStorage.getItem(COUNT_KEY) || 0;
  try {
    window.sessionStorage.setItem(COUNT_KEY, (currentValue + 1) % 3);
  } catch {}

  // We only load a toast on every other opportunity
  if (currentValue % 2 === 0) {
    return true;
  }

  return true;
}

async function loadToast() {
  const data = await http.get('/notifications/v2/toast').json();

  if (data) {
    setupToast(data);
  }
}

function setupToast(data) {
  appendStringIntoElement(toastContainer, data.html);
  const toast = document.querySelector('.toast-notification');

  if (!toast) {
    return;
  }

  // TODO this shoud be DRYed out. Probably a super class situation.
  if (data.type === 'house-ad-ticker' && shouldShowToast()) {
    setupTicker(toast);
  } else if (data.type === 'email-signup' && shouldShowToast()) {
    setupEmailSignup(toast);
  } else if (data.type === 'email-signup-v2') {
    setupEmailSignupV2(toast);
  }
}

function setupEmailSignup(/** @type {Element} */ toast) {
  let closeTimer;
  let focused = false;
  const DISAPPEARANCE_DELAY = 15000; // 15s
  const APPEARANCE_DELAY = 8000; // 8s

  window.dispatchEvent(createEvent('tmp_detect_email_signup'));

  setTimeout(() => {
    toast.classList.add('toast-active');

    trackEvent('email_signup_toast_impression', { version: 1 });

    closeTimer = setTimeout(closeToast, DISAPPEARANCE_DELAY);
  }, APPEARANCE_DELAY);

  function closeToast() {
    toast.classList.remove('toast-active');

    toast.addEventListener('transitionend', () => {
      toast.remove();
    });
  }

  toast.querySelector('.close-button').addEventListener('click', (event) => {
    closeToast();
    event.preventDefault();
    event.stopPropagation();
  });

  toast.addEventListener('mouseover', () => {
    window.clearTimeout(closeTimer);
  });

  toast.addEventListener('mouseout', () => {
    if (!focused) {
      closeTimer = window.setTimeout(closeToast, DISAPPEARANCE_DELAY);
    }
  });

  function onFocus() {
    focused = true;
    window.clearTimeout(closeTimer);
  }

  toast.addEventListener('focus', onFocus);
  toast.addEventListener('input', onFocus);

  window.addEventListener('tmp_email_signup_success', () => {
    window.setTimeout(closeToast, 3000);
  });
}

function setupEmailSignupV2(/** @type {Element} */ toast) {
  let closeTimer;
  let focused = false;
  const DISAPPEARANCE_DELAY = 15000; // 15s
  const APPEARANCE_DELAY = 2000; // 2s

  renderClassToElement(EmailSignupService, '.email-signup-v2-js');

  function openToast() {
    toast.classList.add('toast-active');
    trackEvent('email_signup_toast_impression', { version: 2 });
    closeTimer = setTimeout(closeToast, DISAPPEARANCE_DELAY);
    openTimeout = null;
  }

  let openTimeout = window.setTimeout(openToast, APPEARANCE_DELAY);

  window.addEventListener(
    'tmp_scroll',
    () => {
      if (openTimeout) {
        window.clearTimeout(openTimeout);
      }

      openToast();
    },
    { once: true }
  );

  function closeToast(_, force = false) {
    if (force || !focused) {
      toast.classList.remove('toast-active');
      toast.addEventListener('transitionend', () => {
        toast.remove();
      });
    }
  }

  toast.querySelector('.close-button').addEventListener('click', (event) => {
    closeToast(null, true);
    event.preventDefault();
    event.stopPropagation();
  });

  toast.addEventListener('mouseover', () => {
    window.clearTimeout(closeTimer);
  });

  toast.addEventListener('mouseout', () => {
    if (!focused) {
      closeTimer = window.setTimeout(closeToast, DISAPPEARANCE_DELAY);
    }
  });

  toast.addEventListener('click', () => {
    focused = true;
    window.clearTimeout(closeTimer);
  });
}

function setupTicker(toast) {
  const TICKER_SPEED = 100; // pixels/second
  const animations = new AnimationContext();

  const delay = parseInt(toast.data('delay'), 10) * 1000; // Time before toast appears
  const numLoops = 2;
  /** @type {HTMLDivElement} */
  const toastBody = toast.querySelector('.toast-body');

  function resize() {
    animations.clear();
    const windowWidth = window.innerWidth;
    const bodyLength = toastBody.getBoundingClientRect().width;

    const arrowRightEdge = toast
      .querySelector('.toast-icon')
      .getBoundingClientRect().right;

    const visibleTickerWidth = windowWidth - arrowRightEdge;

    animations.insertKeyframe(
      'toast-ticker',
      [
        'from {\n transform: translate3d(' +
          visibleTickerWidth +
          'px, 0, 0); \n}',
        'to {\n transform: translate3d(-' + bodyLength + 'px, 0, 0);\n}',
      ].join('\n')
    );

    const tickerDistance = visibleTickerWidth + bodyLength;
    const duration = tickerDistance / TICKER_SPEED;

    toastBody.style.animationName = 'toast-ticker';
    toastBody.style.animationDuration = `${duration}s`;
    toastBody.style['-webkit-animation-name'] = 'toast-ticker';
    toastBody.style['-webkit-animation-duration'] = `${duration}s`;
    toastBody.style['-moz-animation-name'] = 'toast-ticker';
    toastBody.style['-moz-animation-duration'] = `${duration}s`;
  }

  resize();
  window.addEventListener('resize', resize);

  setTimeout(() => {
    toast.classList.add('toast-active');

    resize();
    // Unset and reset the animation name to get around a bug in mobile
    // Webkit (Chrome and Safari) that was causing the animation to not play
    // if the name and 'runnin' were set at different times.
    toastBody.style.animationName = '';

    _delay(() => {
      toastBody.style.animationPlayState = 'running';
      toastBody.style.animationName = 'toast-ticker';
    }, 1);
    trackEvent('toast_banner_impression', {
      title: toast.querySelector('.toast-title').textContent,
    });
  }, delay);

  function closeToast() {
    toast.classList.remove('toast-active');
    toast.addEventListener('transitionend', () => {
      toast.remove();
    });
  }

  let completionCount = 0;

  toastBody.addEventListener('animationiteration', () => {
    completionCount += 1;

    if (completionCount === numLoops) {
      closeToast();
    }
  });

  toast.addEventListener('click', () => {
    completionCount = 0;
    trackEvent('toast_banner_click', {
      title: toast.querySelector('.toast-title').textContent,
    });

    window.sessionStorage.setItem(RESPONDED_KEY, 'true');
    window.location.assign(toast.getAttribute('data-href'));
  });

  toast.querySelector('.close-button').addEventListener('click', (event) => {
    closeToast();
    event.preventDefault();
    event.stopPropagation();
  });
}
