document.body.addEventListener('click', (event) => {
  const jump = event.target.closest('a[data-anchor-jump]');

  if (jump) {
    event.preventDefault();
    const jumpId = jump.getAttribute('data-anchor-jump');
    const land = document.querySelector(`[data-anchor-land="${jumpId}"]`);
    const headerHeight = document
      .querySelector('header')
      .getBoundingClientRect().height;
    const top =
      land.getBoundingClientRect().top - headerHeight + window.scrollY;

    window.scrollTo({
      behavior: 'smooth',
      top,
    });
  }
});
