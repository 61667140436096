/**
 * Accepts an element and a string of HTML and injects the string into the element.
 *
 * @param {Element} element
 * @param {string} string
 */
export function appendStringIntoElement(element, string) {
  // TODO: Use a template element instead of a document fragment once we are done with IE11
  const fragment = document.createRange().createContextualFragment(string);
  element.appendChild(fragment);
}

/**
 * Sets the CSS style of the specified element.
 *
 * @param {HTMLElement} element
 * @param {string} property
 * @param {any} value
 */
export function setStyle(element, property, value) {
  element.style.setProperty(property, value);
}
