// packages
import throttle from 'underscore/modules/throttle.js';

const NAVBAR_HEIGHT = 64;
const MOBILE_NAVBAR_HEIGHT = 42;

let lastHeights = [];

function setDynamicResizeScrollbarWidthCSSVar() {
  // This is a CSS variable used on fullbleed elements
  // .fullbleed-container and .photo-max-shim. See `article.scss.erb`
  document.documentElement.style.setProperty(
    '--dynamic-resize-scrollbar-width',
    `${window.innerWidth - document.body.clientWidth}px`
  );
}

// initial call
setDynamicResizeScrollbarWidthCSSVar();

// throttled call on resize event
window.addEventListener(
  'resize',
  throttle(setDynamicResizeScrollbarWidthCSSVar, 100)
);

function resizeDynamicElements() {
  const photoMaxShims = document.querySelectorAll('.photo-max-shim');

  photoMaxShims.forEach((shim) => {
    const photo = shim.querySelector('.photo-max');

    window.requestAnimationFrame(() => {
      // let it paint, then calc new height and update shim
      shim.style.height = `${photo.getBoundingClientRect().height}px`;
    });
  });

  // full-height is an attempt to deal with the fact that most mobile
  // browsers trigger vertical resizes quite frequently when browser chrome
  // minimizes or expands. This causes ugly jank that repositions the whole
  // page when one or more non-positioned elements are set to 100vh height.
  // So instead we set elements to 100vh initially, then fix their heights
  // in pixels and only resize them when the height has changed > 120 pixels.
  const windowWidth = window.document.documentElement.clientWidth;
  const windowHeight = window.document.documentElement.clientHeight;

  if (windowWidth <= 768) {
    const maxLastHeights = lastHeights.map((height) =>
      Math.abs(height - windowHeight)
    );
    const maxDiff = Math.max(...maxLastHeights);

    if (lastHeights.length === 0 || maxDiff > 120) {
      const fullHeightElements = document.querySelectorAll('.full-height');

      fullHeightElements.forEach((element) => {
        element.style.height = `${windowHeight}px`;
      });

      const targetHeight =
        windowHeight -
        (windowWidth < 740 ? MOBILE_NAVBAR_HEIGHT : NAVBAR_HEIGHT);

      const fullHeightMinusHeaderElements = document.querySelectorAll(
        '.full-height-minus-header'
      );

      fullHeightMinusHeaderElements.forEach((element) => {
        element.style.height = `${targetHeight}px`;
      });

      // Reset the baseline
      lastHeights = [];

      lastHeights.push(windowHeight);
    }
  } else {
    const fullHeightElements = document.querySelectorAll(
      '.full-height, .full-height-minus-header'
    );

    fullHeightElements.forEach((element) => {
      element.style.height = null;
    });
  }
}

resizeDynamicElements();
window.requestAnimationFrame(() => {
  resizeDynamicElements();
});

setTimeout(() => {
  resizeDynamicElements();
}, 10);
setTimeout(() => {
  resizeDynamicElements();
}, 1000);
setTimeout(() => {
  resizeDynamicElements();
}, 2000);
setTimeout(() => {
  resizeDynamicElements();
}, 5000);

window.addEventListener('tmp_recalc_max_photos', () => {
  resizeDynamicElements();
});
// rate-limited window.resize
window.addEventListener('tmp_resize', () => {
  resizeDynamicElements();
});
