// packages
import throttle from 'underscore/modules/throttle.js';

// local
import { getCsrfToken } from '$shared/http.js';
import { createEvent } from './util/events.js';

const htmlElement = document.documentElement;
htmlElement.classList.remove('no-js');
htmlElement.classList.add('has-js');

window.last_mousemove = new Date().getTime();
window.last_scroll = new Date().getTime();
window.last_resize = new Date().getTime();

// keeping this until we know we're done using $.ajax and friends
if (typeof $ !== 'undefined' && typeof $.ajaxSetup === 'function') {
  $.ajaxSetup({
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', getCsrfToken());
    },
  });
}
window.dispatchEvent(createEvent('tmp_ready'));

window.addEventListener(
  'mousemove',
  throttle(() => {
    window.last_mousemove = new Date().getTime();
  }, 500)
);

window.addEventListener(
  'scroll',
  throttle(() => {
    window.last_scroll = new Date().getTime();
    window.dispatchEvent(createEvent('tmp_scroll'));
  }, 50)
);

window.addEventListener(
  'resize',
  throttle(() => {
    window.last_resize = new Date().getTime();
    set_device_class();
    window.dispatchEvent(createEvent('tmp_resize'));
  }, 100)
);

function set_device_class() {
  const w = window.innerWidth;
  const body = document.body;

  if (w < 740) {
    body.classList.remove('desktop');
    body.classList.remove('tablet');
    body.classList.add('mobile');
  } else if (w >= 740 && w < 1200) {
    body.classList.remove('desktop');
    body.classList.add('tablet');
    body.classList.remove('mobile');
  } else {
    body.classList.add('desktop');
    body.classList.remove('tablet');
    body.classList.remove('mobile');
  }
}

set_device_class();
