window._hidden = false;

function isDocumentHidden() {
  // modern API: https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState
  if (document.visibilityState) {
    return document.visibilityState === 'hidden';
  }

  // old API: https://developer.mozilla.org/en-US/docs/Web/API/Document/hidden
  return document.hidden;
}

document.addEventListener(
  'visibilitychange',
  () => {
    if (isDocumentHidden()) {
      window._hidden = true;
    } else {
      window._hidden = false;
    }
  },
  false
);
