// local
import { getCookie, setCookie } from '../util/cookiehelpers.js';

if (document.referrer.includes('facebook.com')) {
  setCookie('_fb_ref', 'true');
}
if (document.referrer.includes('twitter.com')) {
  setCookie('_tw_ref', 'true');
}

let max_scroll = 0;

window.addEventListener('tmp_scroll', () => {
  const scroll = window.scrollY;

  if (scroll > max_scroll) {
    max_scroll = scroll;
  }
});

window.max_scroll = () => max_scroll;

// Increment cookie, UserTotalPageView
const utpCookieName = '_utpv'; // user total pageviews
let total_pageviews;
const total_pageviews_raw = getCookie(utpCookieName);

if (total_pageviews_raw === null) {
  total_pageviews = 1;
} else {
  total_pageviews = parseInt(total_pageviews_raw, 10);
  total_pageviews += 1;
}
setCookie(utpCookieName, total_pageviews);
window._utpv = total_pageviews;

// Set a cookie, UserFirstSeen, if it is not already present. Calculate the time between that cookie and now.
const ufsCookieName = '_ufs';

if (getCookie(ufsCookieName) === null) {
  setCookie(ufsCookieName, '' + new Date().getTime());
}

const delta_ms = new Date().getTime() - parseInt(getCookie(ufsCookieName), 10);
const delta = Math.floor(delta_ms / 1000); // ms -> seconds
window._ufs_delta = delta;

let totalSelections = 0;
const selections = [];

function getSelectionText() {
  let text = '';

  if (window.getSelection) {
    text = window.getSelection().toString();
  } else if (document.selection && document.selection.type != 'Control') {
    text = document.selection.createRange().text;
  }

  return text;
}

document.addEventListener('mouseup', () => {
  const text = getSelectionText();

  if (text.length >= 4) {
    // TODO: is what is this threshold, why bother?
    totalSelections += 1;

    selections.push({ time: new Date().getTime(), text });
  }
});

function getSelectionCount() {
  return totalSelections;
}

window.getSelectionCount = getSelectionCount;

function getSelectionHistory() {
  return selections;
}
window.getSelectionHistory = getSelectionHistory;

const pageLoadTime = new Date().getTime();
const samples = [];
const lastSample = 0; // timestamp
const sampleDelta = 100; // milliseconds

window.addEventListener('scroll', () => {
  const now = new Date().getTime();

  if (now - lastSample > sampleDelta) {
    samples.push({
      time: now - pageLoadTime,
      scrollPosition: window.scrollY,
    });
  }
});

function getScrollSamples() {
  return samples;
}
window.getScrollSamples = getScrollSamples;
