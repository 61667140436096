const ratio = 9 / 16;

const iframe_config_ratios = [
  {
    domain: 'youtube.com',
    ratio,
  },
  {
    domain: 'mediamtvnservq-a.akamaihd.net',
    ratio,
  },
  {
    domain: 'vimeo.com',
    ratio,
  },
  {
    domain: 'facebook.com',
    ratio,
  },
  {
    domain: 'player.ooyala.com',
    ratio,
  },
];

function resize_iframes() {
  const iframes = document.querySelectorAll('iframe');

  iframes.forEach((iframe) => {
    const host = iframe.getAttribute('src');

    if (!host) {
      return;
    }

    const config = iframe_config_ratios.find((candidate) => {
      return host.includes(candidate.domain);
    });

    if (!config) {
      return;
    }

    const width = iframe.getBoundingClientRect().width;
    const height = Math.ceil(width * config.ratio);

    window.requestAnimationFrame(() => {
      iframe.setAttribute('height', height);
    });
  });
}

resize_iframes();

window.addEventListener('tmp_resize', () => {
  resize_iframes();
});

setTimeout(() => {
  resize_iframes();
}, 500);
