// packages
import { nanoid } from 'nanoid';

// utils
import { createEvent } from '../util/events.js';

function enabledElements() {
  return document.querySelectorAll('[data-hotzone]');
}

function elementTop(/** @type {Element} */ el) {
  return el.getBoundingClientRect().top;
}

function isActive(/** @type {Element} */ el) {
  let hotzoneTopRatio = 1 / 12.0;
  let hotzoneBottomRatio = 1 / 3.0;

  const str_hotzoneTopRatio = el.getAttribute('data-hotzone-top-ratio');
  if (str_hotzoneTopRatio) {
    hotzoneTopRatio = parseFloat(str_hotzoneTopRatio);
  }

  const str_hotzoneBottomRatio = el.getAttribute('data-hotzone-bottom-ratio');
  if (str_hotzoneBottomRatio) {
    hotzoneBottomRatio = parseFloat(str_hotzoneBottomRatio);
  }

  const windowHeight = window.innerHeight;
  const hotzoneTop = hotzoneTopRatio * windowHeight;
  const hotzoneBottom = hotzoneBottomRatio * windowHeight;

  if (elementTop(el) >= hotzoneTop && elementTop(el) <= hotzoneBottom) {
    return true;
  } else {
    return false;
  }
}

function dispatchIfEdge(
  /** @type {Element} */ el,
  /** @type {string} */ incomingEventName
) {
  if (el.getAttribute('data-hotzone-last-event') !== incomingEventName) {
    el.setAttribute('data-hotzone-last-event', incomingEventName);
    el.dispatchEvent(createEvent(incomingEventName));
    document.dispatchEvent(createEvent('tmp-hotzone-change'));
  }
}

function checkVisibility(/** @type {Element} */ el) {
  const lastState = el.getAttribute('data-visibility') === 'true';

  const scrollTop = window.scrollY;
  const windowHeight = window.innerHeight;
  const bounds = el.getBoundingClientRect();
  const elHeight = bounds.height;
  const elTop = bounds.top + scrollTop;

  const aboveBottom = elTop < scrollTop + windowHeight;
  const belowTop = elHeight + elTop > scrollTop;
  const currentState = aboveBottom && belowTop;

  if (currentState !== lastState) {
    el.setAttribute('data-visibility', currentState);
    if (currentState === true) {
      el.dispatchEvent(createEvent('tmp_hotzone_will_appear'));
    } else {
      el.dispatchEvent(createEvent('tmp_hotzone_will_disappear'));
    }
  }
}

function updateElements() {
  enabledElements().forEach((el) => {
    if (isActive(el)) {
      el.setAttribute('data-hotzone-state', 'on');
      dispatchIfEdge(el, 'tmp_hotzone_start');
    } else {
      el.setAttribute('data-hotzone-state', 'off');
      dispatchIfEdge(el, 'tmp_hotzone_end');
    }

    checkVisibility(el);
  });
}

window.addEventListener('tmp_scroll', () => {
  updateElements();
});

updateElements();

// youtube hotzone
// <div data-youtube-hotzone data-youtube-id="nfWlot6h_JM" data-controls="0" data-showinfo="0"></div> is the form to generate this.
// See https://developers.google.com/youtube/player_parameters for the parameters you can pass (not 100% implemented)

function loadYoutubeAPI() {
  if (window._youtube_api_loaded === true) {
    return true;
  }
  const script_tag = document.createElement('script');
  script_tag.src = 'https://www.youtube.com/iframe_api';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(script_tag, firstScriptTag);
  window._youtube_api_loaded = true;
}

function findYouTubePlayers() {
  // find YouTube players, inject YouTube API if we find one
  document.querySelectorAll('[data-youtube-id]').forEach((element) => {
    // Mobile browsers seem to have trouble with the JS API,
    // see https://developers.google.com/youtube/iframe_api_reference#Mobile_considerations
    // So we fall back to a normal iframe, passing the same query parameters.
    // This is a super crude mobile browser indicator. Could certainly be made better.
    if (window.innerWidth < 768) {
      buildMobileIframe(element);
    } else {
      loadYoutubeAPI();
    }
  });
}

findYouTubePlayers();

function setupYoutubePlayers() {
  document
    .querySelectorAll('[data-youtube-hotzone]')
    .forEach(function (element) {
      buildYouTubePlayer(element);
    });
}

window.onYouTubeIframeAPIReady = () => {
  // this needs to be on window so the YouTube API can find it. keep the rest scoped inside here.
  setupYoutubePlayers();
};

function getPlayerVars(source_el) {
  function attr(name, defaultValue) {
    return source_el.getAttribute(name) !== null
      ? source_el.getAttribute(name)
      : defaultValue;
  }
  const videoId = source_el.getAttribute('data-youtube-id');

  const playerVars = {
    controls: attr('data-controls', 1),
    loop: attr('data-loop', 1),
    autoplay: attr('data-autoplay', 0),
    fs: attr('data-fs', 1),
    list: attr('data-list'),
    modestbranding: attr('data-modestbranding', 1),
    playsinline: attr('data-playsinline', 0),
    rel: attr('data-rel', 0),
    showinfo: attr('data-showinfo', 1),
    start: attr('data-start'),
    end: attr('data-end'),
  };

  if (playerVars.loop) {
    playerVars.playlist = videoId;
  }

  return playerVars;
}

function buildYouTubePlayer(/** @type {Element} */ source_el) {
  const videoPlayerId = 'youtube_' + nanoid();
  source_el.id = videoPlayerId;

  const playerVars = getPlayerVars(source_el);

  const videoId = source_el.getAttribute('data-youtube-id');

  const player = new YT.Player(videoPlayerId, {
    height: '400',
    width: '100%',
    videoId,
    playerVars,
    events: {
      onReady: onPlayerReady,
      onStateChange: onPlayerStateChange,
    },
  });

  const el = document.getElementById(videoPlayerId);

  el.setAttribute('data-hotzone', 'true');

  el.addEventListener('tmp_hotzone_start', () => {
    if (player !== undefined) {
      player.playVideo();
    } else {
      console.log('player undefined during hotzone start');
    }
  });

  el.addEventListener('tmp_hotzone_end', () => {
    if (player !== undefined) {
      player.pauseVideo();
    } else {
      console.log('player undefined during hotzone end');
    }
  });
}

function onPlayerStateChange() {
  // noop
}
function onPlayerReady() {
  // noop
}

function buildMobileIframe(/** @type {Element} */ source_el) {
  const playerVars = getPlayerVars(source_el);
  const queryString = Object.keys(playerVars)
    .map((key) => {
      if (playerVars[key] === undefined) {
        return '';
      }

      return key + '=' + playerVars[key];
    })
    .join('&');

  const videoId = source_el.getAttribute('data-youtube-id');
  const iframe = document.createElement('iframe');
  iframe.width = '100%';
  iframe.height = '315';
  iframe.src = `https://www.youtube.com/embed/${videoId}?${queryString}`;
  iframe.frameBorder = '0';
  iframe.allowFullscreen = true;

  source_el.parentNode.replaceChild(iframe, source_el);
}

function getIframeSrc(videoId, playerId) {
  return [
    'https://player.vimeo.com/video/',
    videoId,
    '?api=1&player_id=',
    playerId,
  ].join('');
}

function insertAfter(newNode, referenceNode) {
  // http://stackoverflow.com/a/4793630
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function buildVimeoPlayer(/** @type {Element} */ source_el) {
  const playerId = 'vimeo_' + nanoid();
  const videoId = source_el.getAttribute('data-vimeo-id');

  const el = document.createElement('iframe');

  el.id = playerId;
  el.setAttribute('data-hotzone', 'true');
  el.src = getIframeSrc(videoId, playerId);
  el.width = '100%';
  el.height = '400';
  el.setAttribute('frameborder', 0);

  insertAfter(el, source_el);
  source_el.remove();

  el.addEventListener('tmp_hotzone_start', () => {
    postToIframe(el, 'play');
  });

  el.addEventListener('tmp_hotzone_end', () => {
    postToIframe(el, 'pause');
  });
}

function postToIframe(el, action, value) {
  var data = { method: action };
  if (value) {
    data.value = value;
  }
  var message = JSON.stringify(data);
  el.contentWindow.postMessage(message, '*');
}

document.querySelectorAll('[data-vimeo-id]').forEach((el) => {
  buildVimeoPlayer(el);
});
