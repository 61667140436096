import { trackEvent } from '../analytics/helpers.js';

const menuButton = document.querySelector('.header-menu-button');
const menuWrapper = document.querySelector('.menu-wrapper');
/** @type {HTMLInputElement} */
const searchInput = document.querySelector('.menu-search-input');
const searchSection = document.querySelector('.menu-search-js');
const lightsOut = document.querySelector('.lights-out');

function onInteraction() {
  if (menuWrapper.classList.contains('menu-hidden')) {
    openMenu();
  } else {
    closeMenu('icon');
  }
}

function removeMenuItemFocus(e) {
  if (this === e.target && menuWrapper.classList.contains('menu-hidden')) {
    menuWrapper.classList.add('menu-unfocusable');
  }
}

menuButton.addEventListener('click', onInteraction);

menuWrapper.addEventListener('transitionend', removeMenuItemFocus);

searchInput.addEventListener('focus', () => {
  if (menuWrapper.classList.contains('menu-hidden')) {
    openMenu();
  }
});

document.body.addEventListener('click', (event) => {
  if (event.target.matches('.lights-out')) {
    closeMenu('offmenu');
  }
});

document.body.addEventListener('keyup', (event) => {
  if (event.key === 'Escape') {
    if (!menuWrapper.classList.contains('menu-hidden')) {
      closeMenu('escape');
    }
  }
});

lightsOut.style.display = 'inline';
lightsOut.style.zIndex = '-999';

function openMenu() {
  menuButton.classList.add('active');
  menuButton.setAttribute('aria-expanded', 'true');
  menuWrapper.classList.remove('menu-hidden', 'menu-unfocusable');
  document.body.classList.add('lock-scrolling');

  dim_page();
  trackEvent('hamburger_menu_opened');
}

function closeMenu(/** @type {string} */ type) {
  document.body.classList.remove('lock-scrolling');
  menuButton.classList.remove('active');
  menuButton.setAttribute('aria-expanded', 'false');
  menuWrapper.classList.add('menu-hidden');
  searchInput.value = '';
  searchInput.blur();
  searchSection.classList.remove('active');

  restore_page();
  trackEvent('hamburger_menu_closed', { type });
}

function dim_page() {
  setTimeout(() => {
    lightsOut.style.opacity = '0.6';
    lightsOut.style.zIndex = '999';
  }, 1);
}

function restore_page() {
  setTimeout(() => {
    lightsOut.style.opacity = '0';
    lightsOut.style.zIndex = '-999';
  }, 1);
}

const menuItemLinkElements = document.querySelectorAll('.menu-item-link');

menuItemLinkElements.forEach((element) => {
  element.addEventListener('click', (event) => {
    trackEvent('hamburger_menu_link_click', { link: event.target.href });
  });
});

searchSection.addEventListener('click', () => {
  searchSection.classList.add('active');
  searchInput.focus();
});

searchInput.addEventListener('focus', () => {
  searchSection.classList.add('active');
});

const menuSearchButton = document.querySelector('.menu-search-button');

menuSearchButton.addEventListener('click', () => {
  if (searchSection.classList.contains('active')) {
    executeSearch();
  }
});

searchInput.addEventListener('keyup', (event) => {
  if (event.key === 'Enter') {
    executeSearch();
  }
});

function executeSearch() {
  const value = searchInput.value;
  window.location.href = `/search?q=${value}`;
}
