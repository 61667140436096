// packages
import debounce from 'underscore/modules/debounce.js';

// local
import { trackEvent } from '../analytics/helpers.js';
import { AnimationContext } from '../animations/animations.js';

const houseAdElements = document.querySelectorAll('.house-ad');

if (houseAdElements.length > 0) {
  const animations = new AnimationContext();

  houseAdElements.forEach((element) => {
    registerListeners(element);
  });

  function resize() {
    animations.clear();

    houseAdElements.forEach((element) => {
      generateAnimations(element);
    });
  }

  resize();

  window.addEventListener('resize', debounce(resize, 300));

  function registerListeners(/** @type {Element} */ element) {
    function onInteraction() {
      element.classList.add('animate');
      element.addEventListener(
        'animationend',
        () => {
          element.classList.remove('animate');
        },
        { once: true }
      );
    }

    element.addEventListener('mouseenter', onInteraction);
    element.addEventListener('touchstart', onInteraction, { passive: true });

    function onTMPHotZoneWillAppear() {
      onInteraction();

      trackEvent('house_ad_impression', {
        title: element.querySelector('.house-ad-title').textContent,
      });
    }

    element.addEventListener('tmp_hotzone_will_appear', onTMPHotZoneWillAppear);

    function onClick() {
      trackEvent('house_ad_click', {
        title: element.querySelector('.house-ad-title').textContent,
      });
    }

    element.addEventListener('click', onClick);
  }

  function generateAnimations(/** @type {Element} */ element) {
    /** @type {SVGSVGElement} */
    const frame = element.querySelector('.house-ad-icon-frame');

    if (!frame) {
      return;
    }

    const length = frame.getTotalLength();
    const id = element.getAttribute('data-id');
    const name = `ad-${id}-anim`;
    const keyframes = `100% { stroke-dashoffset: ${2 * length}px; }`;

    animations.insertKeyframe(name, keyframes);

    const animRule = `.house-ad-${id}.animate .house-ad-icon-frame { animation-name: ${name}; }`;
    animations.insertRule(animRule);
    frame.style.strokeDasharray = length.toString();
  }
}
