// packages
import defaults from 'underscore/modules/defaults.js';

// local
import http from '$shared/http.js';
import { getCookie } from '../util/cookiehelpers.js';
import { trackEvent } from '../analytics/helpers.js';
import { createEvent } from '../util/events.js';

function findTargets() {
  return document.querySelectorAll('.email-onboard');
}

function getContext(/** @type {Element} */ el) {
  return JSON.parse(el.getAttribute('data-context'));
}

function setContext(/** @type {Element} */ el, context) {
  el.setAttribute('data-context', JSON.stringify(context));
}

function recalcEl(/** @type {Element} */ el) {
  const { width, height } = el.getBoundingClientRect();

  const context = getContext(el);

  context.checkboxes = el.getAttribute('data-checkboxes') === 'true';
  // if (context.checkboxes && !context.occasionalEnabled) {
  //  // this introduces a bug where if check boxes are enabled, but the screen shinks,
  //  // then the screen expands again, they won't be re-enabled. wontfix for now.
  //  context.checkboxes = (width >= 1010); // this is a JavaScript breakpoint, basically.
  // }
  context.oneLine = height <= 70; // this is a JavaScript breakpoint, basically.
  context.emailValid = true;
  context.formFloatRight = width >= 880;

  setContext(el, context);
  render(el);
}

function dynamicSizing(/** @type {Element} */ el) {
  window.addEventListener('tmp_resize', () => {
    recalcEl(el);
  });

  recalcEl(el);
}

function successCallback() {
  document.querySelector('.email-signup').style.display = 'none';
}

function failureCallback(
  /** @type {Element} */ el,
  /** @type {string} */ message
) {
  const response = el.querySelector('.response');
  response.innerHTML = message;
}

function submitEmail(
  /** @type {Element} */ el,
  /** @type {string} */ email,
  options
) {
  if (email === '') {
    console.error('no email address provided');
    return;
  }

  const submit = el.querySelector('.submit');
  const input = el.querySelector('.email-address');

  el.querySelector('.response').textContent = '';

  const previousSubmitLabel = submit.textContent;
  submit.textContent = 'Saving...';
  submit.classList.add('in-progress');
  submit.setAttribute('disabled', '');
  input.setAttribute('disabled', '');

  const placement = el.getAttribute('data-placement') || 'unknown';

  options = options || {};
  const endpoint = '/api/v3/email/subscribe';

  const payload = {
    signup: {
      email,
      options,
      placement,
      url: window.location.href,
      referer: document.referrer,
      t: getCookie('t'),
      _utpv: getCookie('_utpv'),
      user_agent: window.navigator.userAgent,
    },
  };

  http
    .post(endpoint, { json: payload })
    .json()
    .then((data) => {
      submit.textContent = 'Thanks!';
      submit.classList.add('complete');
      successCallback(data);

      trackEvent('web-email-signup', 'subscribe', placement);
      window.dispatchEvent(createEvent('tmp_email_signup_success'));

      // this is confusing, but the JSON data returned does _not_ use HTTP headers
      // to communicate this, it actually sets "status" to "redirect"
      if (data.status === 'redirect' && data.redirect_url) {
        window.location.assign(data.redirect_url);
      }
    })
    .catch(async (error) => {
      const data = await error.response.json();

      submit.setAttribute('value', previousSubmitLabel);
      submit.classList.remove('in-progress');
      submit.removeAttribute('disabled');
      input.removeAttribute('disabled');
      submit.textContent = previousSubmitLabel;

      failureCallback(el, data.error.message);
    });
}

function getOptions(/** @type {Element} */ el) {
  const context = getContext(el);

  let options;

  if (context.checkboxes) {
    const daily =
      el.querySelector('.checkbox-daily').getAttribute('data-checked') ===
      'true';
    const weekly =
      el.querySelector('.checkbox-weekly').getAttribute('data-checked') ===
      'true';
    const occasional =
      el.querySelector('.checkbox-occasional').getAttribute('data-checked') ===
      'true';

    options = {
      daily,
      weekly,
      occasional,
    };
  } else {
    // if we don't have checkboxes visible, enroll in the default segments
    options = defaults(
      {
        daily: context.dailyEnabled,
        weekly: context.weeklyEnabled,
        occasional: context.occasionalEnabled,
      },
      {
        daily: true,
        weekly: true,
        occasional: false,
      }
    );
  }

  return options;
}

function registerHandlers(/** @type {Element} */ el) {
  el.addEventListener('click', (event) => {
    if (event.target.matches('.submit')) {
      const email = el.querySelector('.email-address').value;
      const options = getOptions(el);

      submitEmail(el, email, options);
    }

    if (event.target.matches('.checkbox-outer')) {
      const fakebox = event.target.querySelector('.fakebox');
      const previousState = fakebox.getAttribute('data-checked') === 'true';

      if (previousState) {
        fakebox.setAttribute('data-checked', 'false');
      } else {
        fakebox.setAttribute('data-checked', 'true');
      }

      updateCheckboxes(el);
    }
  });

  if (window.innerWidth > 700) {
    el.addEventListener('keydown', (event) => {
      if (event.target.matches('input.email-address')) {
        if (event.key === 'Enter') {
          const email = el.querySelector('.email-address').value;
          const options = getOptions(el);

          submitEmail(el, email, options);
        }
      }
    });
  } else {
    // skip "enter" key for email submit
  }
}

function updateCheckboxes(/** @type {Element} */ el) {
  const fakeboxes = el.querySelectorAll('.fakebox');

  fakeboxes.forEach((el) => {
    const checked = el.getAttribute('data-checked') === 'true';

    if (checked) {
      el.innerHTML = '&#x2714;&#xfe0e;';
    } else {
      el.innerHTML = '&nbsp;';
    }
  });
}

function render(/** @type {Element} */ el) {
  const wrapper = el.closest('.email-onboard-container-wrapper');

  if (wrapper && wrapper.classList.contains('rendered')) {
    // already rendered
    return;
  }

  const context = JSON.parse(el.getAttribute('data-context'));
  el.innerHTML = '';

  let components = [];

  if (context.onlyForm) {
    components.push(templates.form(context.form));
  } else {
    components = [
      templates.copy(context),
      templates.archive_link(context),
      templates.form(context),
      templates.checkboxes(context),
    ];
  }

  components.forEach((child) => {
    if (child) {
      el.appendChild(child);
    }
  });

  updateCheckboxes(el);
}

function buildEl(
  /** @type {string} */ tag,
  /** @type {string} */ contents,
  /** @type {string} */ className
) {
  const el = document.createElement(tag);
  el.className = className;
  el.innerHTML = contents;

  return el;
}

function div(/** @type {string} */ contents, /** @type {string} */ className) {
  return buildEl('div', contents, className);
}

function span(/** @type {string} */ contents, /** @type {string} */ className) {
  return buildEl('span', contents, className);
}

function link(
  /** @type {string} */ href,
  /** @type {string} */ contents,
  /** @type {string} */ className
) {
  var el = buildEl('a', contents, className);
  el.href = href;
  return el;
}

const templates = {
  copy(context) {
    if (!context.copy) {
      return null;
    }

    const el = div('', 'copy');
    el.appendChild(span(context.title, 'title'));
    el.appendChild(span(context.body, 'body'));

    return el;
  },

  archive_link(context) {
    if (context.archiveLink) {
      return link(context.archiveLink, 'Most Recent Email', 'archive-link');
    } else {
      return null;
    }
  },

  checkboxes(context) {
    if (context.checkboxes === false) {
      return null;
    }

    const boxes = [
      '<div class="checkbox-outer"><div class="fakebox checkbox-daily"  data-value="daily"   data-checked="true"></div> Opening Statement – Daily</div>',
      '<div class="checkbox-outer"><div class="fakebox checkbox-weekly" data-value="weekly"  data-checked="true"></div> Closing Argument – Weekly</div>',
    ];

    if (context.occasionalEnabled === true) {
      boxes.push(
        '<div class="checkbox-outer"><div class="fakebox checkbox-occasional" data-value="occasional"  data-checked="true"></div> President\'s Brief – Bi-Weekly</div>'
      );
    }

    return div(boxes.join(''), 'checkboxes');
  },

  form(context) {
    const buttonLabel = 'Subscribe';
    const placeholder = 'email@example.com';

    let className = 'form';

    if (context.formFloatRight) {
      className += ' float-right ';
    }

    return div(
      [
        '<input type="email" name="email" value="" class="email-address" placeholder="' +
          placeholder +
          '">',
        '<button type="submit" class="submit">' + buttonLabel + '</button>',
        '<span class="response"></span>',
      ].join(''),
      className
    );
  },
};

function generateContext(/** @type {Element} */ el) {
  const checkboxes = el.getAttribute('data-checkboxes');
  const occasionalEnabled = el.getAttribute('data-include-occasional');
  const dailyEnabled = el.getAttribute('data-include-daily');
  const weeklyEnabled = el.getAttribute('data-include-weekly');
  const archiveLink = el.getAttribute('data-archive-link');
  const copy = el.getAttribute('data-copy') !== 'false';

  return {
    title: 'Opening Statement',
    body: 'The best criminal justice news, delivered to your inbox daily.',
    archiveLink,
    copy,
    checkboxes,
    occasionalEnabled,
    dailyEnabled,
    weeklyEnabled,
  };
}

function boot() {
  const targets = findTargets();

  targets.forEach((el) => {
    if (el.getAttribute('data-rendered') === 'true') {
      return;
    }

    el.setAttribute('data-rendered', 'true');

    const context = generateContext(el);

    setContext(el, context);
    render(el);
    registerHandlers(el);
    dynamicSizing(el);

    // used to enable CSS
    const wrapper = el.closest('.email-onboard-container-wrapper');

    if (wrapper) {
      wrapper.classList.add('rendered');
    }
  });
}

window.addEventListener('tmp_detect_email_signup', () => {
  boot();
});

boot();
